import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroller';
import CandidateCard from './CandidateCard';
import { incrementPage, activateCandidate } from '../actions/galleryActions';
import { infiniteOff } from '../actions/index';

const hidden = {
  pointerEvents: 'none'
}

const Gallery = ({ zoom, results, page, incrementPage, active, activateCandidate, infinite }) => (
  <InfiniteScroll
    style={active ? hidden : {} }
    pageStart={1}
    loadMore={() => {
      setTimeout(() => {
        if(page * 40 < 1100){
          incrementPage();
        }
      }, 100);
    }}
    hasMore={infinite}
    className={ (zoom === 0 && 'gallery__small') || (zoom === 1 && 'gallery__medium') || (zoom === 2 && 'gallery__large')}>
    {results.slice(0, 40 * page).map((candidate, i) => {
      return (
      <Link to={{
        pathname: candidate.fileName,
        state: candidate
      }}
      onClick={() => {
        activateCandidate();
      }}
      key={i} >
        <CandidateCard {...candidate}/>
      </Link>
    )})};
  </InfiniteScroll>
);

const mapDispatchToProps = dispatch => bindActionCreators({
  incrementPage,
  activateCandidate,
  infiniteOff
}, dispatch);

const mapStateToProps = ( state ) => ({
  results: state.message.results,
  page: state.gallery.page,
  zoom: state.gallery.zoom,
  active: state.gallery.candidateActive,
  infinite: state.message.infinite
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
