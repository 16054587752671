import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Range } from 'rc-slider';
import { setZoom, activateAbout } from '../actions/galleryActions';
import { toggleFilter } from '../actions/index';
import 'rc-slider/assets/index.css';

import logo from '../images/logo.svg';
import hamburger from '../images/hamburger.svg'

const selected = {
  border: '1px solid grey',
  fontWeight: 'bold',
}

const Header = ({ setZoom, toggleFilter, zoom, activateAbout }) => {
  return (
    <>
    <div className='header-logo-wrapper'>
      <img className='header-logo' src={logo} alt='cpad logo'/>
    </div>
    <header className='header'>
       <div className='header-controls'>
        <button className='header-filterbutton' onClick={toggleFilter}>
          <span>Filters</span>
          <img src={hamburger} alt='' />
        </button>
        <section className="header-right-wrapper">
          <div className='header-zoom-buttons'>
            <button
              onClick={(e) => {
                e.preventDefault();
                setZoom(0);
              }}
              style={ zoom === 0 ? selected : {} }
            >a</button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setZoom(2);
              }}
              style={ zoom === 2 ? selected : {} }
            >A</button>
            </div>
            <div className='header-about-range-wrapper'>
            <button className='header-aboutbutton'
              onClick={() => {
                activateAbout();
              }}>About</button>
            <div className='header-range-wrapper'>
              <span>a</span>
              <Range
                className='header-range'
                count={0}
                min={0}
                max={2}
                value={[zoom]}
                onChange={(event) => {
                  setZoom(event[0])
                }}
              />
              <span>A</span>
              </div>
            </div>
          </section>
        </div>
        {/*window.addEventListener('resize', () => {
          if(window.innerWidth < 450 && zoom === 1){
            alert(zoom);
            setZoom(0);
          }
        })*/}
        {/*window.addEventListener('load', () => {
          // this is a hack and I should remove
          if(window.innerWidth < 450 && zoom === 1){
            setZoom(0);
          }
        })*/}
    </header>
    </>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setZoom,
  toggleFilter,
  activateAbout,
}, dispatch);

const mapStateToProps = ( state ) => ({
  zoom: state.gallery.zoom
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
