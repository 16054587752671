import { SET_CURRENT_CANDIDATE,
  SET_CANDIDATES,
  SET_SELECTED_CANDIDATES,
  SET_YEARS,
  SET_PARTIES,
  SET_STATES,
  SET_RACES,
  SET_GENDERS,
  SET_COLORS,
  SET_DISTRICTS,
  SET_RESULTS,
  SET_FONTS,
  SET_CHARACTERISTICS,
  SET_INCUMBENT,
  TOGGLE_FILTER,
  INFINITE_OFF } from '../actions/index';

import TAFFY from 'taffy';

let taffy = TAFFY([]);

const initSearch = taffy({}).order('name');

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

const initialState = {
  candidates: [],
  results: initSearch.get(),
  currentCandidate: {
    name: '',
    year: '',
    state: '',
    race: '',
    districtLean: '',
    electionResult: '',
    incumbent: '',
    gender: '',
    primaryColor: '',
    primaryFont: ''
  },
  selectedYears: [],
  selectedParties: [],
  selectedCandidates: [],
  selectedStates: [],
  selectedRaces: [],
  selectedGenders: [],
  selectedColors: [],
  selectedDistricts: [],
  selectedResults: [],
  selectedFonts: [],
  selectedCharacteristics: [],
  selectedIncumbent: [],
  availableYears: initSearch.distinct('year'),
  availableParties: initSearch.distinct('party'),
  availableCandidates: initSearch.distinct('name'),
  availableStates: initSearch.distinct('state'),
  availableRaces: initSearch.distinct('race'),
  availableGenders: initSearch.distinct('gender'),
  availableColors: initSearch.distinct('primaryColor'),
  availableDistricts: initSearch.distinct('districtLean'),
  availableResults: initSearch.distinct('results'),
  availableFonts: flatten(initSearch.distinct('primaryFont')).filter((v, i, a) => a.indexOf(v) === i),
  availableCharacteristics: flatten(initSearch.distinct('characteristics')).filter((v, i, a) => a.indexOf(v) === i),
  availableIncumbent: initSearch.distinct('incumbent'),
  filterDisplay: false,
  infinite: true
};

function search(data, state, touchesFonts = false, touchesCharacteristics = false) {

  let query = {};

  if (state.selectedYears.length !== 0) {
    query['year'] = state.selectedYears;
  }
  if(state.selectedParties.length !== 0){
    query['party'] = state.selectedParties;
  }
  if(state.selectedCandidates.length !== 0){
    query['name'] = state.selectedCandidates;
  }
  if(state.selectedStates.length !== 0){
    query['state'] = state.selectedStates;
  }
  if(state.selectedRaces.length !== 0){
    query['race'] = state.selectedRaces;
  }
  if(state.selectedGenders.length !== 0){
    query['gender'] = state.selectedGenders;
  }
  if(state.selectedColors.length !== 0){
    query['primaryColor'] = state.selectedColors;
  }
  if(state.selectedDistricts.length !== 0){
    query['districtLean'] = state.selectedDistricts;
  }
  if(state.selectedResults.length !== 0){
    query['electionResult'] = state.selectedResults;
  }
  if(state.selectedIncumbent.length !== 0){
    query['incumbent'] = state.selectedIncumbent;
  }

  query = {...query, ...data}

  if(query.year && query.year.length === 0){
    delete query.year;
  }
  if(query.party && query.party.length === 0){
    delete query.party;
  }
  if(query.name && query.name.length === 0){
    delete query.name;
  }
  if(query.state && query.state.length === 0){
    delete query.state;
  }
  if(query.race && query.race.length === 0){
    delete query.race;
  }
  if(query.gender && query.gender.length === 0){
    delete query.gender;
  }
  if(query.incumbent && query.incumbent.length === 0){
    delete query.incumbent;
  }
  if(query.primaryColor && query.primaryColor.length === 0){
    delete query.primaryColor;
  }
  if(query.districtLean && query.districtLean.length === 0){
    delete query.districtLean;
  }
  if(query.electionResult && query.electionResult.length === 0){
    delete query.electionResult;
  }
  if(query.primaryFont && query.primaryFont.length === 0){
    delete query.primaryFont;
  }
  let primaryFont;
  if(query.primaryFont && query.primaryFont.length > 0){
    primaryFont = query.primaryFont;
    delete query.primaryFont;
  }
  if(query.characteristics && query.characteristics.length === 0){
    delete query.characteristics;
  }
  let characteristics;
  if(query.characteristics && query.characteristics.length > 0){
    characteristics = query.characteristics;
    delete query.characteristics;
  }

  taffy = TAFFY(state.candidates);

  var results = taffy(query).order('name').get();
  // Special queries, since fonts and characteristics are arrays
  // of possible values and not just a single string
  if((touchesFonts || (primaryFont && primaryFont.length > 0))
    || (!touchesFonts && state.selectedFonts.length > 0)){
    let newarr = [];
    primaryFont = touchesFonts ? (primaryFont || []) : state.selectedFonts;
    if (primaryFont.length) {
      primaryFont.forEach(font => {
        results.forEach(result => {
          if (result.primaryFont && result.primaryFont.indexOf(font) !== -1) {
            if (newarr.indexOf(result) === -1) {
              newarr.push(result);
            }
          }
        });
      });
      results = newarr;
    }
  }
  if((touchesCharacteristics || (characteristics && characteristics.length > 0))
    || (!touchesCharacteristics && state.selectedCharacteristics.length > 0)){
    let newarr = [];
    characteristics = touchesCharacteristics ? (characteristics || []) : state.selectedCharacteristics;
    if (characteristics.length) {
      characteristics.forEach(characteristic => {
        results.forEach(result => {
          if (result.characteristics && result.characteristics.indexOf(characteristic) !== -1) {
            if (newarr.indexOf(result) === -1) {
              newarr.push(result);
            }
          }
        });
      });
      results = newarr;
    }
  }

  return {
    results: results.sort(function(a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
  }
}

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case SET_CURRENT_CANDIDATE:
      const { results } = search({fileName: data}, state)
      return {
        ...state,
        currentCandidate: results && results[0]
      }
    case SET_CANDIDATES:
      taffy = TAFFY(data);
      const loadSearch = taffy({}).order('name');
      return {
        ...state,
        ...search({}, state),
        candidates: data,
        results: loadSearch.get(),
        availableYears: loadSearch.distinct('year'),
        availableParties: loadSearch.distinct('party'),
        availableCandidates: loadSearch.distinct('name'),
        availableStates: loadSearch.distinct('state'),
        availableRaces: loadSearch.distinct('race'),
        availableGenders: loadSearch.distinct('gender'),
        availableColors: loadSearch.distinct('primaryColor'),
        availableDistricts: loadSearch.distinct('districtLean'),
        availableResults: loadSearch.distinct('electionResult'),
        availableFonts: flatten(loadSearch.distinct('primaryFont')).filter((v, i, a) => a.indexOf(v) === i),
        availableCharacteristics: flatten(loadSearch.distinct('characteristics')).filter((v, i, a) => a.indexOf(v) === i),
        availableIncumbent: loadSearch.distinct('incumbent'),
      }

    case SET_SELECTED_CANDIDATES:
      return {
        ...state,
        ...search({name: data}, state),
        selectedCandidates: data,
      };
    case SET_YEARS:
      return {
        ...state,
        ...search({year: data}, state),
        selectedYears: data,
      };
    case SET_PARTIES:
      return {
        ...state,
        ...search({party: data}, state),
        selectedParties: data,
      };
    case SET_STATES:
      return {
        ...state,
        ...search({state: data}, state),
        selectedStates: data,
      };
    case SET_RACES:
      return {
        ...state,
        ...search({race: data}, state),
        selectedRaces: data,
      };
    case SET_GENDERS:
      return {
        ...state,
        ...search({gender: data}, state),
        selectedGenders: data,
      };
    case SET_COLORS:
      return {
        ...state,
        ...search({primaryColor: data}, state),
        selectedColors: data,
      };
    case SET_DISTRICTS:
      return {
        ...state,
        ...search({districtLean: data}, state),
        selectedDistricts: data,
      };
    case SET_RESULTS:
      return {
        ...state,
        ...search({electionResult: data}, state),
        selectedResults: data,
      };
    case SET_FONTS:
      return {
        ...state,
        ...search({primaryFont: data}, state, true, false),
        selectedFonts: data,
      };
    case SET_CHARACTERISTICS:
      return {
        ...state,
        ...search({characteristics: data}, state, false, true),
        selectedCharacteristics: data,
      };
    case SET_INCUMBENT:
      let sliceData = data.slice(-1).pop() === undefined ? [] : [data.slice(-1).pop()];
      return {
        ...state,
        ...search({incumbent: sliceData}, state),
        selectedIncumbent: sliceData,
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        filterDisplay: !state.filterDisplay,
      };
    case INFINITE_OFF:
      debugger;
      return {
        ...state,
        infinite: false,
      };

    default:
      return state;
  }
}
