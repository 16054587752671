import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router, Route
} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import {getClient} from '../lib/Contentful';

import rootReducer from '../reducers/index';
import {SET_CANDIDATES, SET_CURRENT_CANDIDATE, SET_YEARS} from '../actions/index';

import Gallery from './Gallery';
import Header from './Header';
import Filter from './Filter';
import Candidate from './Candidate';
import About from './About';
import Footer from './Footer';

import '../style/reset.scss';
import '../style/base.scss';
import '../style/header.scss';
import '../style/gallery.scss';
import '../style/candidatecard.scss';
import '../style/candidate.scss';
import '../style/filter.scss';
import '../style/about.scss';
import '../style/footer.scss';

const store = createStore(rootReducer, {}, composeWithDevTools(),);

const getPage = async (skip, preview) => await getClient(preview).getEntries({
    'content_type': 'campaign', 'skip': skip * (1000), 'limit': 1000
}).then(data => data.items.map(({fields}) => fields).filter(fields => fields).map(fields => ({
    ...fields, electionResult: fields.electionResult || 'TBD'
})))

const getAllData = async (preview) => await Promise.all([getPage(0, preview), getPage(1, preview), getPage(2, preview)])

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const candidates = [];
        const preview=params.get('preview')==='true'

        const setData = () => {
            const currentYear=candidates.map(({year})=>year).reduce((a,b)=>Math.max(a,b))
            store.dispatch({type: SET_CANDIDATES, data: candidates});
            store.dispatch({type: SET_CURRENT_CANDIDATE, data: window.location.pathname.replace('/', '')});
            store.dispatch({type: SET_YEARS, data: [currentYear]});
            setIsLoading(false);
        }
        getAllData(preview).then(resp => resp.flatMap(arr => arr).forEach(item => candidates.push(item))).then(() => setData())
    }, []);
    return (<Provider store={store}>
            <Router>
                <div>
                    {isLoading && (<div className="loading-screen">
                            <img className="loading-screen-gif" src="loading-alt-crop.gif" alt="loading"></img>
                            <p>Loading...</p>
                        </div>)}
                    <About/>
                    <Header/>
                    <Filter/>
                    <Gallery/>
                    <Route path='/:candId' component={Candidate}/>
                    <Footer/>
                </div>
            </Router>
        </Provider>)
}

export default App;
