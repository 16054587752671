import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import classnames from 'classnames';
import { connect } from 'react-redux';

import states from '../states';
// sizes="(max-width: 320px) 280px,
//             (max-width: 480px) 440px,
//             800px"
const CandidateCard = ({ zoom, state, name, party, race, year, incumbent, filename, logo, gender, primaryFont, characteristics, primaryColor, districtLean, district }) => {
  return (
    <div className={classnames(`year-${year}`, {
      'candidate-card__small': zoom === 0,
      'candidate-card__medium': zoom === 1,
      'candidate-card__large': zoom === 2
      })}>
      {logo ? (
        <ResponsiveImage
          name={name}
          url={logo.fields.file.url}
          className='candidate-card-logo'
          sizes={ (zoom === 0 && '(max-width: 700px 150px, 60px') || (zoom === 1 && '122px') || (zoom === 2 && '(max-width:1024px) 100vw, 488px') } />
      ) : (
        <div className="responsive-image-wrapper"></div>
      )}
      <div className='candidate-card-text'>
        <ul className='candidate-card-name'>
          <li>{`${name} (${party})`}</li>
          {race !== 'US-Territories' && (
            <li>{states[state]}{race === 'House' ? district && `-${parseInt(district)}` : ''}{states[state] ? ',' : ''}</li>
          )}
          <li>{race},</li>
          <li>{year}</li>
        </ul>
        <ul className='candidate-card-specifics'>
          <li>{districtLean}</li>
          <li>{incumbent}</li>
          <li>{gender}</li>
        </ul>
        <ul className='candidate-card-design'>
          <li>{primaryFont && (primaryFont.toString())}</li>
          <li>{primaryColor && (primaryColor.toString())}</li>
        </ul>
      </div>
    </div>
  )
}

// const mapDispatchToProps = dispatch => bindActionCreators({
//   setZoom
// }, dispatch);

const mapStateToProps = ( state ) => ({
  zoom: state.gallery.zoom
});

export default connect(mapStateToProps)(CandidateCard);
