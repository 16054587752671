import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inactivateAbout } from '../actions/galleryActions';

import x from '../images/x.svg';
import ScrollLockComponent from './utils/scrollLockComponent';
import TwitterIcon from '../svg/twitter';
import InstagramIcon from '../svg/instagram';
import {getClient} from '../lib/Contentful';

const About = ({about, inactivateAbout}) => {
  const [pressItems, setPressItems] = useState([]);
  useEffect(() => {
    const params= new URLSearchParams(window.location.search);
    const preview=params.get('preview')==='true'
    const getData = async () => {
      const entries = await getClient(preview).getEntries({
        'content_type': 'pressPage',
        'limit': 1000
      });
      return entries.items[0];
    }
    getData().then(data => {
      setPressItems(data.fields.pressItems || []);
    })
  }, []);
  return (
    <>
      {about && (
        <ScrollLockComponent />
      )}
      <section className={about ? 'about' : 'about about__hidden'}>
        <button
          onClick={() => {
            inactivateAbout();
          }} className='about-x'>
          <img src={x} alt='' />
        </button>
        <div className='container'>
          <div className="about__column thin">
            <div className="about__image small">
              <img src="/cfapd_about_logo.png" alt="CFAPD Logo" />
            </div>
            <h3 className="about__header about__header-page">About CAPD</h3>
            <h1 className="about__sub-header">The Center for American Politics and Design (CAPD) is a research group investigating the graphic vernacular of American politics.</h1>
          </div>
        </div>
        <div className="container container--spacing-top">
          <div className='about__text about__text-column'>
            <p>The first of its kind, this collection consists of campaign logos from the 2018 election onwards for Congressional, Presidential and Gubernatorial races. The archive is a tool to explore trends and typologies that reveal themselves only when viewed in aggregate.</p>
            <p>Founded in 2018, CAPD aims to increase political literacy among designers and to foster a dialogue about the role of design in the American democratic process.</p>
            <p>Our complete dataset is available upon request; we welcome anyone to use this collection to conduct their own analyses.</p>
          </div>
          <div className="about__text about__text-column">
            <h2>Members</h2>
            <ul className='about-members'>
              <li>Lukas Bentel</li>
              <li>Will Denton</li>
              <li>Susan Merriam</li>
              <li>Seth Kranzler</li>
              <li>Kevin Wiesner</li>
            </ul>
            <h2>Partner Studios</h2>
            <ul className='about-members'>
              <li><a href='https://www.channel.studio/' target='_blank' rel="noopener noreferrer">Channel Studio</a></li>
              <li><a href='https://www.hellovelocity.com/' target='_blank' rel="noopener noreferrer">Hello Velocity</a></li>
            </ul>
            <h2>Site By</h2>
            <li><a href="https://www.channel.studio/" className="no-underline" target="_blank" rel="noopener noreferrer"><figure className="CS-logo alt"></figure></a></li>
          </div>
          <div className="about__image">
            <img src="/cfapd_about_logo.png" alt="CFAPD Logo" />
          </div>
        </div>
        <div className="container container--spacing-top large">
          <div className="about__column">
            <h3 className="about__sub-header">Contact</h3>
          </div>
          <ul className="about__text about-contact container--spacing-top">
            <li><a href="mailto:info@politicsanddesign.com">info@politicsanddesign.com</a></li>
            <li>
              <a href="https://twitter.com/politicsndesign" className="no-underline" target="_blank" rel="noopener noreferrer"><TwitterIcon /></a>
              <a href="https://www.instagram.com/politicsndesign/" className="no-underline" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
            </li>
          </ul>
        </div>
        {pressItems.length && (
          <div className="container container--spacing-top large">
            <div className="about__column">
              <h3 className="about__sub-header">Press</h3>
            </div>
            <div className="about__text about__text-press container--spacing-top">
              {pressItems.map((item, index) => {
                return (
                  <p key={index}><a href={item.fields.link} target="_blank" rel="noopener noreferrer" >{item.fields.text}</a></p>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  inactivateAbout,
}, dispatch);

const mapStateToProps = ( state ) => ({
  about: state.gallery.about,
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
