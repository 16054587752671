export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const SET_ZOOM = 'SET_ZOOM';
export const ACTIVATE_CANDIDATE = 'ACTIVATE_CANDIDATE';
export const INACTIVATE_CANDIDATE = 'INACTIVATE_CANDIDATE';
export const ACTIVATE_ABOUT = 'ACTIVATE_ABOUT';
export const INACTIVATE_ABOUT = 'INACTIVATE_ABOUT';

export function incrementPage(page) {
  return {
    type: 'INCREMENT_PAGE',
    data: page,
  };
}

export function setZoom(zoom) {
  return {
    type: 'SET_ZOOM',
    data: zoom,
  }
}

export function activateCandidate() {
  return {
    type: 'ACTIVATE_CANDIDATE',
    data: true,
  }
}

export function inactivateCandidate() {
  return {
    type: 'INACTIVATE_CANDIDATE',
    data: false,
  }
}


export function activateAbout() {
  return {
    type: 'ACTIVATE_ABOUT',
    data: true,
  }
}

export function inactivateAbout() {
  return {
    type: 'INACTIVATE_ABOUT',
    data: false,
  }
}
