import React from 'react';

export default function Footer() {
  return (
    <footer className="Footer">
      <a href="https://channel.studio" target="_blank" rel="noopener noreferrer" className="CS-logo__link">
        <figure className="CS-logo"></figure>
        <figcaption className="CS-logo__text">Site by Channel Studio</figcaption>
      </a>
    </footer>
  );
};
