import React from 'react';

const ResponsiveImage = ({url, name, sizes}) => {

  return (
  <picture className="responsive-image-wrapper">
    <source
      type="image/webp"
      srcSet={`${url}?w=400&fm=webp&q=80 200w, ${url}?w=600&fm=webp&q=80 300w, ${url}?w=800&fm=webp&q=80 400w, ${url}?w=1600&fm=webp&q=80 800w, ${url}?w=2000&fm=webp&q=80 1000w, ${url}?w=2400&fm=webp&q=80 1200w, ${url}?w=3600&fm=webp&q=80 1800w`}
      sizes={sizes}
    />
    <img srcSet={`${url}?w=400&fm=jpg&q=80 200w, ${url}?w=600&fm=jpg&q=80 300w, ${url}?w=800&fm=jpg&q=80 400w, ${url}?w=1600&fm=jpg&q=80 800w, ${url}?w=2000&fm=jpg&q=80 1000w, ${url}?w=2400&fm=jpg&q=80 1200w, ${url}?w=3600&fm=jpg&q=80 1800w`}
      sizes={sizes}
      src={`${url}?w=1800&fm=jpg&q=80 1800w`}
      alt={`Campaign logo for ${name}`} />
  </picture>
)};

export default ResponsiveImage;
