import { combineReducers } from 'redux';

import message from './filterReducer';
import gallery from './galleryReducer';

const rootReducer = combineReducers({
  message,
  gallery,
});

export default rootReducer;
