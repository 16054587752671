import React, { useEffect } from 'react';
import ResponsiveImage from './ResponsiveImage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { inactivateCandidate } from '../actions/galleryActions';
import { setCurrentCandidate } from '../actions/index';

import x from '../images/x.svg';
import ScrollLockComponent from './utils/scrollLockComponent';

const Candidate = ({history, inactivateCandidate, currentCandidate, setCurrentCandidate}) => {
  useEffect(() => {
    setCurrentCandidate(window.location.pathname.replace('/', ''));
    // console.log('we should have set...', currentCandidate);
  }, []);
  return (
    <>
      <ScrollLockComponent />
      { currentCandidate &&
        <div className='candidate-wrapper'
        onClickCapture={(e) => {
          if (!hasSomeParentWithClass(e.target, 'candidate') || hasSomeParentWithClass(e.target, 'candidate-x')) {
            inactivateCandidate();
            history.push('/');
          }
        }}>
          <div className='candidate'>
            <button
              onClick={() => {
                inactivateCandidate();
                history.push('/');
              }} className='candidate-x'>
              <img src={x} alt='' />
            </button>
            {currentCandidate.logo && (
              <ResponsiveImage
                name={currentCandidate.name}
                url={currentCandidate.logo.fields.file.url}
                className='candidate-logo'
                sizes='(max-width: 1024px) calc(35vw), (max-width: 1440px) calc(20vw), 407px'
              />
            )}
            <div className='candidate-text'>
              <ul className='candidate-name'>
                <li>{`${currentCandidate.name} (${currentCandidate.party})`}</li>
                <li>{currentCandidate.state}, {currentCandidate.race}</li>
                <li>{currentCandidate.year}</li>
              </ul>
              <ul className='candidate-specifics'>
                <li>{currentCandidate.districtLean}</li>
                <li>{currentCandidate.incumbent}</li>
                <li>{currentCandidate.gender}</li>
              </ul>
              <ul className='candidate-design'>
                <li>{currentCandidate.primaryFont && currentCandidate.primaryFont.toString()}</li>
                <li>{currentCandidate.primaryColor}</li>
              </ul>
            </div>
            {window.addEventListener('keydown', (event) => {
              if(event.keyCode === 27) {
                  inactivateCandidate();
                  history.push('/');
              }
            })}
          </div>
        </div>
      }
    </>
  )
}

const hasSomeParentWithClass = (element, classname) => {
  if (element && element.className && element.className.split(' ').indexOf(classname)>=0) return true;
  return element.parentNode && hasSomeParentWithClass(element.parentNode, classname);
}

const mapDispatchToProps = dispatch => bindActionCreators({
  inactivateCandidate,
  setCurrentCandidate,
}, dispatch);

const mapStateToProps = ( state ) => ({
  currentCandidate: state.message.currentCandidate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
