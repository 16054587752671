import {
  INCREMENT_PAGE,
  SET_ZOOM,
  ACTIVATE_CANDIDATE,
  INACTIVATE_CANDIDATE,
  ACTIVATE_ABOUT,
  INACTIVATE_ABOUT } from '../actions/galleryActions';

const initialState = {
  page: 1,
  zoom: 1,
  candidateActive: false,
  about: false,
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1
      };
    case SET_ZOOM:
      return {
        ...state,
        zoom: data
      }
    case ACTIVATE_CANDIDATE:
      return {
        ...state,
        candidateActive: true
      }
    case INACTIVATE_CANDIDATE:
      return {
        ...state,
        candidateActive: false
      }
    case ACTIVATE_ABOUT:
      return {
        ...state,
        about: true
      }
    case INACTIVATE_ABOUT:
      return {
        ...state,
        about: false
      }

    default:
      return state;
  }
}
