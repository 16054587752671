export const SET_CURRENT_CANDIDATE = 'SET_CURRENT_CANDIDATE';
export const SET_CANDIDATES = 'SET_CANDIDATES';
export const SET_SELECTED_CANDIDATES = 'SET_SELECTED_CANDIDATES';
export const SET_YEARS = 'SET_YEARS';
export const SET_PARTIES = 'SET_PARTIES';
export const SET_STATES = 'SET_STATES';
export const SET_RACES = 'SET_RACES';
export const SET_GENDERS = 'SET_GENDERS';
export const SET_COLORS = 'SET_COLORS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_RESULTS = 'SET_RESULTS';
export const SET_FONTS = 'SET_FONTS';
export const SET_CHARACTERISTICS = 'SET_CHARACTERISTICS';
export const SET_INCUMBENT = 'SET_INCUMBENT';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const INFINITE_OFF = 'INFINITE_OFF';

export function setCurrentCandidate(candidateName) {
  return {
    type: 'SET_CURRENT_CANDIDATE',
    data: candidateName
  }
}

export function setCandidates(candidates) {
  return {
    type: 'SET_CANDIDATES',
    data: candidates
  };
}

export function setSelectedCandidates(candidates) {
  return {
    type: 'SET_SELECTED_CANDIDATES',
    data: candidates
  };
}

export function setYears(years) {
  return {
    type: 'SET_YEARS',
    data: years
  };
}

export function setParties(parties) {
  return {
    type: 'SET_PARTIES',
    data: parties
  };
}

export function setStates(states) {
  return {
    type: 'SET_STATES',
    data: states
  };
}

export function setRaces(races) {
  return {
    type: 'SET_RACES',
    data: races
  };
}

export function setGenders(genders) {
  return {
    type: 'SET_GENDERS',
    data: genders
  };
}

export function setColors(colors) {
  return {
    type: 'SET_COLORS',
    data: colors
  };
}

export function setDistricts(districts) {
  return {
    type: 'SET_DISTRICTS',
    data: districts
  };
}

export function setResults(results) {
  return {
    type: 'SET_RESULTS',
    data: results
  };
}

export function setFonts(fonts) {
  return {
    type: 'SET_FONTS',
    data: fonts
  };
}

export function setCharacteristics(characteristics) {
  return {
    type: 'SET_CHARACTERISTICS',
    data: characteristics
  };
}

export function setIncumbent(incumbent) {
  return {
    type: 'SET_INCUMBENT',
    data: incumbent
  };
}

export function toggleFilter() {
  return {
    type: 'TOGGLE_FILTER'
  };
}

export function infiniteOff() {
  return {
    type: 'INFINITE_OFF'
  };
}
