import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import x from '../images/x.svg';

import { setSelectedCandidates,
  setYears,
  setParties,
  setStates,
  setRaces,
  setGenders,
  setColors,
  setDistricts,
  setResults,
  setFonts,
  setCharacteristics,
  setIncumbent,
  toggleFilter } from '../actions/index';

import Select from 'react-select';

const Filter = ({
  selectedCandidates,
  selectedYears,
  selectedParties,
  selectedStates,
  selectedRaces,
  selectedGenders,
  selectedColors,
  selectedDistricts,
  selectedResults,
  selectedFonts,
  selectedCharacteristics,
  selectedIncumbent,
  availableYears,
  availableParties,
  availableCandidates,
  availableStates,
  availableRaces,
  availableGenders,
  availableColors,
  availableDistricts,
  availableResults,
  availableFonts,
  availableCharacteristics,
  availableIncumbent,
  setSelectedCandidates,
  setYears,
  setParties,
  setStates,
  setRaces,
  setGenders,
  setColors,
  setDistricts,
  setResults,
  setFonts,
  setCharacteristics,
  setIncumbent,
  results,
  toggleFilter,
  filterDisplay
  }) => {
    return (
      <>
        <div
          className={classnames('filter__overlay', {
            active: filterDisplay
          })}
          onClick={toggleFilter}
        ></div>
        <div className={ filterDisplay ? 'filter filter__display' : 'filter' }>
          <button
            className='filter-x'
            onClick={toggleFilter}>
            <img src={x} alt='' />
          </button>
          <div className='filter-innerwrapper'>
            <h1>Color</h1>
            <div className="filter-button-wrapper">
              {availableColors.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames(`filter-button filter-button-color ${item}`, {
                    active: selectedColors.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedColors.indexOf(item) === -1) {
                      selectedColors.push(item);
                    } else {
                      selectedColors.splice(selectedColors.indexOf(item), 1);
                    }
                    setColors(selectedColors);
                  }}>
                    <span className="color-swatch"></span>
                    <span className="color-name">{item.length > 5 ? `${item.substring(0, 3)}.` : item}</span>
                  </button>
                )
              })}
            </div>
            <h1>Typography</h1>
            <div className="filter-button-wrapper">
              {availableFonts.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedFonts.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedFonts.indexOf(item) === -1) {
                      selectedFonts.push(item);
                    } else {
                      selectedFonts.splice(selectedFonts.indexOf(item), 1);
                    }
                    setFonts(selectedFonts);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>Year</h1>
            <div className="filter-button-wrapper">
              {availableYears.sort((a, b) => b - a).filter(item => item).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedYears.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedYears.indexOf(item) === -1) {
                      selectedYears.push(item);
                    } else {
                      selectedYears.splice(selectedYears.indexOf(item), 1);
                    }
                    setYears(selectedYears);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>Party</h1>
            <div className="filter-button-wrapper">
              {availableParties.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedParties.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedParties.indexOf(item) === -1) {
                      selectedParties.push(item);
                    } else {
                      selectedParties.splice(selectedParties.indexOf(item), 1);
                    }
                    setParties(selectedParties);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>District Lean</h1>
            <div className="filter-button-wrapper">
              {availableDistricts.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedDistricts.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedDistricts.indexOf(item) === -1) {
                      selectedDistricts.push(item);
                    } else {
                      selectedDistricts.splice(selectedDistricts.indexOf(item), 1);
                    }
                    setDistricts(selectedDistricts);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>Election Result</h1>
            <div className="filter-button-wrapper">
              {availableResults.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedResults.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedResults.indexOf(item) === -1) {
                      selectedResults.push(item);
                    } else {
                      selectedResults.splice(selectedResults.indexOf(item), 1);
                    }
                    setResults(selectedResults);
                  }}>{item.toUpperCase()}</button>
                )
              })}
            </div>
            <h1>Gender</h1>
            <div className="filter-button-wrapper">
              {availableGenders.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedGenders.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedGenders.indexOf(item) === -1) {
                      selectedGenders.push(item);
                    } else {
                      selectedGenders.splice(selectedGenders.indexOf(item), 1);
                    }
                    setGenders(selectedGenders);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>Office</h1>
            <div className="filter-button-wrapper">
              {availableRaces.filter(item => item && item.length).map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedRaces.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedRaces.indexOf(item) === -1) {
                      selectedRaces.push(item);
                    } else {
                      selectedRaces.splice(selectedRaces.indexOf(item), 1);
                    }
                    setRaces(selectedRaces);
                  }}>{item}</button>
                )
              })}
            </div>
            <h1>Incumbency</h1>
            <div className="filter-button-wrapper">
              {availableIncumbent.filter(item => typeof item !== "undefined").map((item, index) => {
                return (
                  <button key={index} className={classnames('filter-button', {
                    active: selectedIncumbent.indexOf(item) !== -1
                  })} onClick={() => {
                    if (selectedIncumbent.indexOf(item) === -1) {
                      selectedIncumbent.push(item);
                    } else {
                      selectedIncumbent.splice(selectedIncumbent.indexOf(item), 1);
                    }
                    setIncumbent(selectedIncumbent);
                  }}>{item.toString()}</button>
                )
              })}
            </div>
            <h1>Iconography</h1>
            <Select
              className='filter-select'
              value={selectedCharacteristics.map((item) => ({ value: item, label: item }))}
              onChange={ vals => {
                setCharacteristics(vals.map(({value}) => value));
              } }
              options={availableCharacteristics.filter(item => item && item.length).sort((a, b) => a.localeCompare(b)).map((item) => ({ value: item, label: item }))}
              isMulti={true}
            />
            <h1>Candidate</h1>
            <Select
              className='filter-select'
              value={selectedCandidates.map((item) => ({ value: item, label: item }))}
              onChange={ vals => {
                setSelectedCandidates(vals.map(({value}) => value));
              } }
              options={availableCandidates.filter(item => item && item.length).map((item) => ({ value: item, label: item }))}
              isMulti={true}
            />
            <h1>State</h1>
            <Select
              className='filter-select'
              value={selectedStates.map((item) => ({ value: item, label: item }))}
              onChange={ vals => {
                setStates(vals.map(({value}) => value));
              } }
              options={availableStates.filter(item => item && item.length).sort((a, b) => a.localeCompare(b)).map((item) => ({ value: item, label: item }))}
              isMulti={true}
            />
          </div>
        </div>
      </>
  )
}

const mapStateToProps = ( state ) => ({
  selectedCandidates: state.message.selectedCandidates,
  selectedYears: state.message.selectedYears,
  selectedParties: state.message.selectedParties,
  selectedStates: state.message.selectedStates,
  selectedRaces: state.message.selectedRaces,
  selectedGenders: state.message.selectedGenders,
  selectedColors: state.message.selectedColors,
  selectedDistricts: state.message.selectedDistricts,
  selectedResults: state.message.selectedResults,
  selectedFonts: state.message.selectedFonts,
  selectedCharacteristics: state.message.selectedCharacteristics,
  selectedIncumbent: state.message.selectedIncumbent,
  availableCandidates: state.message.availableCandidates,
  availableYears: state.message.availableYears,
  availableParties: state.message.availableParties,
  availableStates: state.message.availableStates,
  availableRaces: state.message.availableRaces,
  availableGenders: state.message.availableGenders,
  availableColors: state.message.availableColors,
  availableDistricts: state.message.availableDistricts,
  availableResults: state.message.availableResults,
  availableFonts: state.message.availableFonts,
  availableCharacteristics: state.message.availableCharacteristics,
  availableIncumbent: state.message.availableIncumbent,
  results: state.message.results,
  filterDisplay: state.message.filterDisplay
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSelectedCandidates,
  setYears,
  setParties,
  setStates,
  setRaces,
  setGenders,
  setColors,
  setDistricts,
  setResults,
  setFonts,
  setCharacteristics,
  setIncumbent,
  toggleFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
